
import { smoothScrollToTargetId } from '@/helpers/util'
import baseConstants from '~/store/base/-constants'
import tutorialConstants from '~/store/tutorial/-constants'

export default {
  name: 'ProjectLayout',
  data() {
    return {
      isScrolled: false,
      sections: []
    }
  },
  computed: {
    i18nActive() {
      return !!this.$store.state.base.meta.generalConfig.i18nActive
    },
    projects() {
      return this.$store.state.project.projects
    },
    landingSections() {
      return this.$store.state.base.meta.landingConfig.sections
    },
    sectionsData() {
      return this.$store.state.base.meta.projectConfig.sections
    },
    menuSectionsTags() {
      return this.sections.filter((s) => s.menuLabel)
    },
    activeProject() {
      return this.$store.state.project.activeProject
    },
    activeSpace() {
      return this.$route.params.space
    },
    containsSpaceSlug() {
      return this.$route.params.space !== undefined
    },
    containsProjectSlug() {
      return this.$route.params.project !== undefined
    },
    generalConfig() {
      return this.$store.state.base.meta.generalConfig
    },
    instagramUrl() {
      if (!this.$store.state.base.meta.social) return ''
      return this.$store.state.base.meta.social.instagramUrl
    },
    linkedinUrl() {
      if (!this.$store.state.base.meta.social) return ''
      return this.$store.state.base.meta.social.linkedinUrl
    },
    facebookUrl() {
      if (!this.$store.state.base.meta.social) return ''
      return this.$store.state.base.meta.social.facebookUrl
    },
    portfolioPageDisabled() {
      return this.$store.state.base.meta.generalConfig.portfolioPageDisabled || false
    },
    staticPages() {
      return this.$store.state.base.meta.staticPages || []
    },
    projectSpecificStaticPages() {
      return this.$store.state.base.meta.projectStaticPages || []
    },
    linkPrefix() {
      if (this.portfolioPageDisabled && !this.containsProjectSlug) return '/'
      if (this.containsSpaceSlug) return `/project/${this.activeProject}/space/${this.activeSpace}`
      return `/project/${this.activeProject}`
    }
  },
  watch: {
    $route: {
      deep: true,
      handler: function (to, from) {
        this.sortSections()
      }
    }
  },
  mounted() {
    document.addEventListener('scroll', this.initScrollListener)

    this.sortSections()
    const self = this
    document.querySelectorAll('a.navigation-link[href*="#"]').forEach((anchor) => {
      anchor.addEventListener('click', self.smoothScroll)
    })
    if (this.$route.hash !== '') {
      document.querySelector(this.$route.hash).scrollIntoView({
        behavior: 'smooth'
      })
    }
  },
  beforeDestroy() {
    document.removeEventListener('scroll', this.initScrollListener)

    const self = this
    document.querySelectorAll('a.navigation-link[href*="#"]').forEach((anchor) => {
      anchor.removeEventListener('click', self.smoothScroll)
    })
    this.$store.dispatch(baseConstants.withNamespace(baseConstants.action.CLOSE_SIDEBAR_STATE))
  },
  methods: {
    initScrollListener() {
      this.isScrolled = window.scrollY > window.innerHeight
    },
    backToTop() {
      smoothScrollToTargetId(`#building`)
    },
    sortSections() {
      let sectionsData = [...this.sectionsData].map((s) => {
        const clone = { ...s }
        if (this.$route.name === 'SpecificSpace' && clone.type === 'Building') clone.order_no = 1
        if (this.$route.name === 'SpecificSpace' && ['home', 'hero'].includes(clone.hashAnchor))
          clone.order_no = 2
        return clone
      })
      if (this.$route.name === 'SpecificSpace') {
        sectionsData = sectionsData.filter((s) => !['home', 'hero'].includes(s.hashAnchor))
      }
      sectionsData = sectionsData.filter((s) => s.enabled)
      this.sections = sectionsData.sort((a, b) => {
        if (a.order_no < b.order_no) {
          return -1
        }
        if (a.order_no > b.order_no) {
          return 1
        }
        return 0
      })
    },
    smoothScroll(e) {
      e.preventDefault()
      document.querySelector(`#${e.target.getAttribute('href').split('#')[1]}`).scrollIntoView({
        behavior: 'smooth'
      })

      const links = this.$el.querySelectorAll('a.navigation-link[href*="#"]')
      links.forEach((link) => {
        const activeLink = e.path[0]
        link.classList.remove('active')
        if (link === activeLink && !activeLink.classList.contains('active')) {
          link.classList.add('active')
        }
      })
    },
    hashChecker(target) {
      if (window.innerWidth < 768) {
        this.$store.dispatch(baseConstants.withNamespace(baseConstants.action.TOGGLE_SIDEBAR))
      }
    }
  }
}
